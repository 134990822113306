import {GET_EVENT_PREROLLS, GET_PAGE_ID_FOR_URL, GET_USER_DATA} from '@/store/getterTypes';
import {PREROLL, STREAM_STATUS, TIME} from '@/const';
import {getStorageItem, setStorageItem, STORAGE_KEYS} from '@/localStorage';

const validCachedPrerollList = () => {
  const now = Date.now();
  return Object.fromEntries(
      Object.entries(getStorageItem(STORAGE_KEYS.DISABLED_PREROLLS) || {})
          .filter(([_, exp]) => {
            return now < exp;
          }),
  );
};

const statusMap = {
  [STREAM_STATUS.vod]: 'for_vod',
  [STREAM_STATUS.live]: 'for_live',
};
export default {
  getChannelRoute(state) {
    const newRoute = [];
    state?.pages?.forEach((page) => {
      newRoute.push({
        path: page.url,
        name: page.name,
        meta: {
          title: page.name,
          scrollToTop: true,
          id: page.id,
        },
        component: () => import(
            /* webpackChunkName: "schedule-view" */ '../../views/CustomPageScreen.vue'),
      });
    });
    return newRoute;
  },
  /**
   * @param {object} state
   * @return {function(string): string|number}
   */
  [GET_PAGE_ID_FOR_URL](state) {
    return (searchUrl) => {
      return state.pages?.find?.(({url, alt_name: alt}) => {
        const correctedSearch = url.startsWith('/') ? `/${searchUrl}` : searchUrl;
        return url === correctedSearch || alt === searchUrl;
      } )?.id;
    };
  },
  [GET_EVENT_PREROLLS]: (state, getters) => (event) => {
    const validList = validCachedPrerollList();
    const allPrerolls = state.settings?.prerolls || [];
    const {
      id: eventId,
      stream_status: eventStatus,
      location_id: eventLocation,
      event_category_id: eventCategory,
    } = event;

    if (!allPrerolls.length) {
      return [];
    }

    const list = [];
    allPrerolls.forEach((preroll) => {
      const {location_id: prerollLocation, event_categories: prerollCategories, id} = preroll;
      let available = preroll[statusMap[eventStatus]];

      if (available && prerollLocation) {
        available = Number(prerollLocation) === Number(eventLocation);
      }
      if (available && prerollCategories?.length) {
        available = prerollCategories.includes(eventCategory);
      }

      if (!available) {
        return;
      }

      const userId = getters[GET_USER_DATA]?.id;
      const prerollKey = `${userId || 'unlogged'}_${eventId}_${id}`;

      if (!Boolean(validList[prerollKey])) {
        list.push({
          ...preroll,
          file: preroll.url,
          onComplete: () => {
            const validList = validCachedPrerollList();
            validList[prerollKey] = Date.now() + (PREROLL.TIME || TIME.HOUR);
            setStorageItem(STORAGE_KEYS.DISABLED_PREROLLS, validList);
          },
        });
      }
    });

    return list.sort((a, b) => b.weight - a.weight);
  },
};
