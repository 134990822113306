import request from './request';
import Vue from 'vue';
import store from '../../store/store';
import {API, API_METHODS} from '@/const';
import * as ACTION from '@/store/actionTypes';
import {
  ADD_SESSION_API,
  API_KEY_API,
  CHANNEL_KEY_API,
  DELETE_ACCOUNT_API,
  EMAIL_VERIFY_API,
  LOGIN_API,
  LOGOUT_API, PASSWORD_RESET_API, PASSWORD_RESET_REQUEST_API,
  PROFILE_API,
  REGISTER_API,
  SOCIAL_AUTH_API,
  USER_ACCESS_API,
} from '@/urls';

const authApi = {
  get_channel_key() {
    return request({
      url: CHANNEL_KEY_API,
      params: {},
      method: API_METHODS.GET,
      baseUrl: API.API_KEYS_HOST,
      config: {
        retryCount: 1,
        globalError: true,
        localError: false,
        retryDelay: 1000,
        crashCodes: ['5\\d\\d', '4\\d\\d'],
        allowedCodes: [],
      },
    }).then((res) => {
      store.cache.clear();
      return res;
    });
  },
  get_api_key(params = {}) {
    if (Vue.$storage.get('api_key')) {
      return {
        data: {api_key: Vue.$storage.get('api_key')},
      };
    }
    Object.assign(params, {
      channel_key: Vue.$storage.get('channel_key'),
    });
    return request({
      url: API_KEY_API,
      method: API_METHODS.POST,
      params,
      config: {
        retryCount: 1,
        globalError: true,
        localError: false,
        retryDelay: 1000,
        crashCodes: ['5\\d\\d', '4\\d\\d'],
        allowedCodes: [],
      },
    });
  },
  profile() {
    return request({
      url: PROFILE_API,
      method: API_METHODS.GET,
      config: {
        retryCount: 1,
        globalError: true,
        localError: false,
        retryDelay: 1000,
        crashCodes: ['5\\d\\d', '4\\d\\d'],
        allowedCodes: [401],
      },
    });
  },
  login: function(params = {}) {
    return request({
      url: LOGIN_API,
      method: API_METHODS.POST,
      params,
    }).then((res) => {
      store.cache.clear();
      return res;
    });
  },
  logout: function() {
    return request({
      url: LOGOUT_API,
      method: API_METHODS.POST,
    }).then(async () => {
      store.cache.clear();
      await resetAuthData();
    });
  },
  registration: function(params = {}) {
    return request({
      url: REGISTER_API,
      method: API_METHODS.POST,
      params,
    }).then((res) => {
      store.cache.clear();
      return res;
    });
  },
  verify: function(params = {}) {
    return request({
      url: EMAIL_VERIFY_API,
      method: API_METHODS.POST,
      params,
    });
  },
  passwordResetRequest: function(params = {}) {
    return request({
      url: PASSWORD_RESET_REQUEST_API,
      method: API_METHODS.POST,
      params,
    });
  },
  passwordReset: function(params = {}) {
    return request({
      url: PASSWORD_RESET_API,
      method: API_METHODS.POST,
      params,
    });
  },
  updateProfile: function(params = {}) {
    Object.assign(params, {_method: 'patch'});
    return request({
      url: PROFILE_API,
      method: API_METHODS.POST,
      params,
    });
  },
  addSessionByCode({code} = {}) {
    const params = {code};
    return request({
      url: ADD_SESSION_API,
      method: API_METHODS.POST,
      params,
    });
  },
  getUserAccess() {
    return request({
      url: USER_ACCESS_API,
      method: API_METHODS.GET,
    });
  },
  socialAuth({
    provider,
    ...params
  } = {}) {
    return request({
      url: `${SOCIAL_AUTH_API}/${provider}`,
      method: API_METHODS.POST,
      params,
    });
  },
  confirmAccountDelete(params = {}) {
    return request({
      url: DELETE_ACCOUNT_API,
      method: API_METHODS.POST,
      params,
    });
  },
};

const resetAuthData = () => {
  Vue.$storage.set('api_key', null);
  return new Promise((resolve) => {
    store.dispatch(ACTION.GET_API_KEY).then(() => {
      resolve(); // После переавторизации обновим странцицу
    });
  });
};

export default authApi;
